::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: rgba(0,0,0, 0);
}

::-webkit-scrollbar-track {
  background-color: rgba(0,0,0, 0);
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: rgba(119, 116, 116, 0.5);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(119, 116, 116, 0.8);
}
